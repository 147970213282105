/**
* Generated automatically at built-time (2024-10-08T07:41:25.871Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "viinikaappi-black-friday",templateKey: "sites/83-dada88ea-9332-4568-b2b4-dd75117a8463"};